body{margin-top:20px;}

.fade-in {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 1s ease-in-out forwards;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
video{
    width: 100%;
    height: 100%;
    object-fit: cover;

}
.masthead {
    padding: 3rem 0 7rem;
    height: 60vh;
    position: relative;
    /* background-color: #dd3d31; */
    background-image: url(https://startbootstrap.com/assets/img/overlay.svg), linear-gradient(45deg, #dd3d31 0%, #d22f23 100%);
    background-size: cover;
    z-index: 0
}
/* Media query for smaller screens */
@media (max-width: 767px) {
    .masthead {
        padding: 1rem 0 2rem; /* Further adjusted padding for extra small screens */
        height: 120vh; /* Further adjusted height for extra small screens */
    }
}

.masthead svg.wave {
    position: absolute;
    bottom: -1px;
    left: 0
}

.masthead h1 {
    color: rgb(32, 124, 86);
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.1;
    z-index: 1
}

.masthead h2 {
    color: purple;
    font-weight: 600;
    z-index: 1;
    font-size: 1.2rem
}

.masthead a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: underline;
    z-index: 1
}

.masthead a:hover {
    color: #fff
}

.masthead a:active {
    text-decoration: none
}

@media(min-width:992px) {
    .masthead h1 {
        font-size: 3rem
    }
    .masthead h2 {
        font-size: 1.5rem
    }
}

.masthead .masthead-cards {
    position: relative;
    z-index: 1
}

.masthead .masthead-cards .shape {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, .3);
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%
}

.masthead .masthead-cards .card {
    opacity: 1;
    font-size: .8rem;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: .05rem;
    color: #212529;
    transition: .15s all
}

.masthead .masthead-cards .card:hover {
    margin-top: -.25rem;
    margin-bottom: .25rem
}

.masthead .masthead-cards .card:active {
    margin-top: inherit;
    margin-bottom: inherit
}

.masthead .masthead-cards .card.border-bottom-blue:hover {
    color: #2092ed
}

.masthead .masthead-cards .card.border-bottom-green:hover {
    color: #28a745
}

.masthead .masthead-cards .card.border-bottom-red:hover {
    color: #dd3d31
}

.masthead .masthead-cards .card.border-bottom-yellow:hover {
    color: #ffc107
}

.masthead-page {
    padding: 1rem 0 4rem
}

@media(min-width:992px) {
    .masthead-page h1 {
        font-size: 2.5rem
    }
}

.border-bottom-yellow {
    border-color: #ffc107!important;
}

.border-bottom-red {
    border-color: #dd3d31!important;
}

.border-bottom-blue {
    border-color: #2092ed!important;
}

.border-bottom-green {
    border-color: #28a745!important;
}

.border-bottom-blue, .border-bottom-green, .border-bottom-red, .border-bottom-yellow {
    border-bottom: .4rem solid!important;
}
.shadow-lg {
    box-shadow: 0 2rem 1.5rem -1.5rem rgba(33,37,41,.15),0 0 1.5rem .5rem rgba(33,37,41,.05)!important;
}
.border-0 {
    border: 0!important;
}
