/* Oligo Synthesis Form*/
/* .row{
  margin-top: 50px;
  margin-bottom: 50px;
} */

.masthead-text{
    height: 300px;
  }
  
  .form-control{
    height: 45px;
  }
  .form-control-2{
    height: 200px;
    background-color: #fff2cc;
  }
  .form-control-3{
    height: 300px;
    background-color: #fff2cc;
  }
  
  select:hover {
    color: #444645;
    background: #ddd;
  }
  
  .login-or {
      position: relative;
      font-size: 18px;
      color: #aaa;
      margin-top: 20px;
      margin-bottom: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .span-or {
      display: block;
      position: absolute;
      left: 50%;
      top: -2px;
      margin-left: -25px;
      background-color: #fff;
      width: 50px;
      text-align: center;
    }
    .hr-or {
      background-color: #cdcdcd;
      height: 1px;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  
  #login-dp{
      min-width: 250px;
      padding: 14px 14px 0;
      overflow:hidden;
      background-color:rgba(255,255,255,.8);
  }
  #login-dp .help-block{
      font-size:12px    
  }
  
  #login-dp .social-buttons{
      margin:12px 0    
  }
  #login-dp .social-buttons a{
      width: 49%;
  }
  #login-dp .form-group {
      margin-bottom: 10px;
  }
  .btn-fb{
      color: #fff;
      background-color:#3b5998;
  }
  .btn-fb:hover{
      color: #fff;
      background-color:#496ebc 
  }
  .btn-tw{
      color: #fff;
      background-color:#55acee;
  }
  .btn-tw:hover{
      color: #fff;
      background-color:#59b5fa;
  }
  @media(max-width:768px){
      #login-dp{
          background-color: inherit;
          color: #fff;
      }
      #login-dp .bottom{
          background-color: inherit;
          border-top:0 none;
      }
  }
  
  .progress {
    height: 5px;
  }
  
  .block-help {
          font-weight: 300;
      }
  
  .hide {
    display: none;
  }