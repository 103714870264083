@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .active-button{


} */

.searchInputs {
    
    display: flex;
  }
  
  .search input {
    background-color: white;
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 15px;
    padding: 15px;
    height: 30px;
    width: 300px;
  }
  
  .searchIcon {
    height: 30px;
    width: 20px;
    background-color: white;
    display: grid;
    place-items: center;
    cursor: pointer;
  }
  
  input:focus {
    outline: none;
  }
  .searchIcon svg {
    font-size: 25px;
  }
  
  .dataResult {
    margin-top: 5px;
    width: 300px;
    height: 200px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
    top: 100%;
    position: absolute;
  }
  
  .dataResult::-webkit-scrollbar {
    display: none;
  }
  
  .dataResult .dataItem {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: black;
  }
  
  .dataItem p {
    margin-left: 10px;
  }
  a {
    text-decoration: none;
  }
  
  a:hover {
    background-color: rgb(103, 222, 115);
  }
  
  #clearBtn {
    cursor: pointer;
  }
/* .IN-PROGRESS{

} */

.NEGATIVE{
    background-color: #008800;
    color: white;
    font-weight: bolder;
    padding-left: 16px;
}

.POSITIVE{
    background-color: #C21F39;
    color: white;
    font-weight: bolder;
    padding-left: 16px;

}


.NO_ISSUES{
    background-color: #008800;
    color: white;
    font-weight: bolder;
    padding-left: 16px;
}
.HOME_QUARANTINE{
    background-color: #ee8816;
    color: black;
    font-weight: bolder;
    padding-left: 16px;
}

.ADMIT{
    background-color: #C21F39;
    color: white;
    font-weight: bolder;
    padding-left: 16px;

}

/* endlessloop image conatiner */
.image-row {
    overflow: hidden;
  }
  
  .image-container {
    transition: -webkit-transform 0.9s ease-in-out;
    transition: transform 0.9s ease-in-out;
    transition: transform 0.9s ease-in-out, -webkit-transform 0.9s ease-in-out;
    display: flex;
    overflow: hidden;
    position: relative;
  }
  
  .active {
    
    border: 2px solid #007bff;
  }

  .image {
    flex: 1 1;
    object-fit: cover;
    width:100px;
    height: 50px;
    margin-left: 50px;
    transition: -webkit-transform 1s ease;
    transition: transform 1s ease;
    transition: transform 1s ease, -webkit-transform 1s ease;
    background-blend-mode: lighten;
  }



  
  .image:not(:last-child) {
    margin-right: 10px; 
  }
  
  @media (max-width: 767px) {
    
    .image-container {
      margin-bottom: 10px;
    }
  }
  
/* bg image about us page */



/* login page design */


.marketplace {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.marketplace .serviceTitle {
  font-family: cursive;
  font-size: 60px;
}

.serviceList {
  width: 70vw;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr ;
  align-items: center;

}

/* MENU ITEM STYLING */

.serviceItem {
  border-radius: 15px;
  width: 285px;
  height: 500px;
  margin: 20px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  
  
}
.serviceItem:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.serviceItem div {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
}




@media only screen and (max-width: 1300px) {
  .serviceList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .serviceList {
    grid-template-columns: 1fr;
  }
}


.Image{
  border-radius: 50%;
}



 
/* About us team */

body{margin-top:20px;}

.fade-in {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    -webkit-animation: fadeIn 1s ease-in-out forwards;
            animation: fadeIn 1s ease-in-out forwards;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

.indexx{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.team_member {
    background: rgba(199,201,209,.09);
	padding-bottom: 50px;
	overflow: hidden;
}
.single_team_content{
padding:45px;
margin-top:60px;
}
.single_team_content h1 {
	font-size: 50px;
	font-weight: 600;
	line-height: 60px;
}

.our-team {
	margin-bottom: 30px;
	box-shadow: 0 10px 40px -10px rgba(0,64,128,.09);
}
.our-team .team_img{
    position: relative;
    overflow: hidden;
}
.our-team .team_img:after{
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.2);
    position: absolute;
    bottom: -100%;
    left: 0;
    transition: all 0.3s ease 0s;
}
.our-team:hover .team_img:after{
    bottom: 0;
}
.our-team img{
    width: 100%;
    height: auto;
}
.our-team .social{
    padding: 0 0 18px 0;
    margin: 0;
    list-style: none;
    position: absolute;
    top: -100%;
    right: 10px;
    background: #ffaa17;
    border-radius: 0 0 20px 20px;
    z-index: 1;
    transition: all 0.3s ease 0s;
}
.our-team:hover .social{
    top: 0;
}
.our-team .social li a{
    display: block;
    padding: 15px;
    font-size: 15px;
    color: #232434;
}
.our-team:hover .social li a:hover{
    color: #fff;
}
.our-team .team-content{
    padding: 20px 0;
    background: #fff;
}
.our-team .title{
    font-size: 18px;
    font-weight: bold;
    color: #ffaa17;
    text-transform: capitalize;
    margin: 0 0 20px;
    position: relative;
}
.our-team .title:before{
    content: "";
    width: 25px;
    height: 1px;
    background: #ffaa17;
    position: absolute;
    bottom: -10px;
    right: 50%;
    margin-right: 9px;
    transition-duration: 0.25s;
}
.our-team .title:after{
    content: "";
    width: 25px;
    height: 1px;
    background: #ffaa17;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: 9px;
    transition-duration: 0.25s;
}
.our-team:hover .title:before,
.our-team:hover .title:after{
    width: 50px;
}
.our-team .post{
    display: inline-block;
    font-size: 15px;
    text-transform: capitalize;
}
.our-team .post:before{
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #ffaa17;
    margin: 0 auto;
    position: relative;
    top: -13px;
}
@media only screen and (max-width: 990px){
    .our-team{ margin-bottom: 30px; }
}


/* AnimatedStatsSection.css */
.fade-in {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    -webkit-animation: fadeIn 1s ease-in-out forwards;
            animation: fadeIn 1s ease-in-out forwards;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  

/* Hover - Publication */
.publication-item {
    transition: background-color 0.1s ease, font-weight 0.1s ease; 
  }
  
  .publication-item:hover {
    background-color: #eafff6; 
    font-weight: bold; 
  }
  .fade-in {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    -webkit-animation: fadeIn 1s ease-in-out forwards;
            animation: fadeIn 1s ease-in-out forwards;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }



body{
    margin-top:20px;
    background-color:#eee;
}
.fade-in {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    -webkit-animation: fadeIn 1s ease-in-out forwards;
            animation: fadeIn 1s ease-in-out forwards;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
.home-5-bg {
    background: #1E90FF;
    position: relative;
    background-size: cover;
    height: 100vh;
    background-position: center center;
}

.section {
    position: relative;
}

.home-5-content {
  z-index: 1;
  position: relative;
}

.bg-overlay {
    background-color: #000;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.home-center {
    display: table;
    width: 100%;
    height: 100%;
}

.home-desc-center {
    display: table-cell;
    vertical-align: middle;
}

.home-5-content {
    z-index: 1;
    position: relative;
}

.text-white-70 {
    color: rgba(255, 255, 255, 0.8);
}

.f-15 {
    font-size: 15px;
}

.home-5-bg #particles-js {
    z-index: 1!important;
position: absolute;
    width: 100%;
    height: 100%;
}

body{margin-top:20px;
background-color:#f2f6fc;
color:#69707a;
}
.img-account-profile {
    height: 10rem;
}
.rounded-circle {
    border-radius: 50% !important;
}
.card {
    box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%);
}
.card .card-header {
    font-weight: 500;
}
.card-header:first-child {
    border-radius: 0.35rem 0.35rem 0 0;
}
.card-header {
    padding: 1rem 1.35rem;
    margin-bottom: 0;
    background-color: rgba(33, 40, 50, 0.03);
    border-bottom: 1px solid rgba(33, 40, 50, 0.125);
}
.form-control, .dataTable-input {
    display: block;
    width: 100%;
    padding: 0.875rem 1.125rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    color: #69707a;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c5ccd6;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.35rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.nav-borders .nav-link.active {
    color: #0061f2;
    border-bottom-color: #0061f2;
}
.nav-borders .nav-link {
    color: #69707a;
    border-bottom-width: 0.125rem;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0;
    padding-right: 0;
    margin-left: 1rem;
    margin-right: 1rem;
}

body{margin-top:20px;}

.fade-in {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    -webkit-animation: fadeIn 1s ease-in-out forwards;
            animation: fadeIn 1s ease-in-out forwards;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
video{
    width: 100%;
    height: 100%;
    object-fit: cover;

}
.masthead {
    padding: 3rem 0 7rem;
    height: 60vh;
    position: relative;
    /* background-color: #dd3d31; */
    background-image: url(https://startbootstrap.com/assets/img/overlay.svg), linear-gradient(45deg, #dd3d31 0%, #d22f23 100%);
    background-size: cover;
    z-index: 0
}
/* Media query for smaller screens */
@media (max-width: 767px) {
    .masthead {
        padding: 1rem 0 2rem; /* Further adjusted padding for extra small screens */
        height: 120vh; /* Further adjusted height for extra small screens */
    }
}

.masthead svg.wave {
    position: absolute;
    bottom: -1px;
    left: 0
}

.masthead h1 {
    color: rgb(32, 124, 86);
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.1;
    z-index: 1
}

.masthead h2 {
    color: purple;
    font-weight: 600;
    z-index: 1;
    font-size: 1.2rem
}

.masthead a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: underline;
    z-index: 1
}

.masthead a:hover {
    color: #fff
}

.masthead a:active {
    text-decoration: none
}

@media(min-width:992px) {
    .masthead h1 {
        font-size: 3rem
    }
    .masthead h2 {
        font-size: 1.5rem
    }
}

.masthead .masthead-cards {
    position: relative;
    z-index: 1
}

.masthead .masthead-cards .shape {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, .3);
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%
}

.masthead .masthead-cards .card {
    opacity: 1;
    font-size: .8rem;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: .05rem;
    color: #212529;
    transition: .15s all
}

.masthead .masthead-cards .card:hover {
    margin-top: -.25rem;
    margin-bottom: .25rem
}

.masthead .masthead-cards .card:active {
    margin-top: inherit;
    margin-bottom: inherit
}

.masthead .masthead-cards .card.border-bottom-blue:hover {
    color: #2092ed
}

.masthead .masthead-cards .card.border-bottom-green:hover {
    color: #28a745
}

.masthead .masthead-cards .card.border-bottom-red:hover {
    color: #dd3d31
}

.masthead .masthead-cards .card.border-bottom-yellow:hover {
    color: #ffc107
}

.masthead-page {
    padding: 1rem 0 4rem
}

@media(min-width:992px) {
    .masthead-page h1 {
        font-size: 2.5rem
    }
}

.border-bottom-yellow {
    border-color: #ffc107!important;
}

.border-bottom-red {
    border-color: #dd3d31!important;
}

.border-bottom-blue {
    border-color: #2092ed!important;
}

.border-bottom-green {
    border-color: #28a745!important;
}

.border-bottom-blue, .border-bottom-green, .border-bottom-red, .border-bottom-yellow {
    border-bottom: .4rem solid!important;
}
.shadow-lg {
    box-shadow: 0 2rem 1.5rem -1.5rem rgba(33,37,41,.15),0 0 1.5rem .5rem rgba(33,37,41,.05)!important;
}
.border-0 {
    border: 0!important;
}

/* Footer */
body {
    overflow-x: hidden;
}
.section-1 {
    padding: 20px 0;
}

.section-1 .section-1-title {
    text-align: center;
    color: #007b5e;
    margin-bottom: 50px;
    text-transform: uppercase;
}
#footer {
    /* background: #007b5e !important; */
    /* background: url("https://images.unsplash.com/photo-1483794344563-d27a8d18014e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D") center center no-repeat; */
    background-size: cover;
}
.h5{
	padding-left: 10px;
    /* border-left: 3px solid #000000; */
    padding-bottom: 2px;
    margin-bottom: 20px;
    color:#000000;
}
.h5-1{
    padding-left: 10px;
    border-left: 3px solid #000000;
    /* border-right: 3px solid #000000; */
    padding-bottom: 2px;
    margin-bottom: 20px;
    color:#000000;
}
.h5-2{
    padding-right: 10px;
    border-right: 3px solid #000000;
    padding-bottom: 6px;
    margin-bottom: 20px;
    color:#000000;
}
#footer a {
    color: #000000;
    text-decoration: none !important;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}
#footer ul.social li{
	padding: 1px 0;
}
#footer ul.social li a i {
    margin-right: 5px;
	font-size:25px;
	transition: .5s all ease;
}
#footer ul.social li:hover a i {
	font-size:30px;
	margin-top:-10px;
}
#footer ul.social li a,
#footer ul.quick-links li a{
	color:#000000;
}
#footer ul.social li a:hover{
	color:#000000;
}
#footer ul.quick-links li{
	padding: 3px 0;
	transition: .5s all ease;
}
#footer ul.quick-links li:hover{
	padding: 3px 0;
	margin-left:5px;
	font-weight:700;
}
#footer ul.quick-links li a i{
	margin-right: 5px;
}
#footer ul.quick-links li:hover a i {
    font-weight: 700;
}
#footer h5 {
    margin-bottom: 15px; /* Adjust the margin to reduce space between items */
  }

@media (max-width:767px){
	#footer h5 {
    padding-left: 0;
    border-left: transparent;
    padding-bottom: 0px;
    margin-bottom: 10px;
}
}


a:hover {
    color: #007bff; /* Change to your preferred hover color */
    text-decoration: underline;
  }
 /* Marketplace */
/* Feature Box */

.feature-box-1 {
    padding: 32px;
    box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
    margin: 15px 0;
    position: relative;
    z-index: 1;
    border-radius: 10px;
    overflow: hidden;
    transition: ease all 0.35s;
    top: 0;
    height: 375px;
  }
  .feature-box-1 * {
    transition: ease all 0.35s;
  }
  .feature-box-1 .icon {
    width: 90px;
    height: 90px;
    line-height: 70px;
    /* background: #fc5356; */
    color: #ffffff;
    text-align: center;
    border-radius: 50%;
    margin-bottom: 22px;
    font-size: 27px;
  }
  .feature-box-1 .icon i {
    line-height: 70px;
  }
  .feature-box-1 h5 {
    color: #20247b;
    font-weight: 600;
  }
  .feature-box-1 p {
    margin: 0;
  }
  .feature-box-1:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: auto;
    right: 0;
    border-radius: 10px;
    width: 0;
    background: #d9ead3;
    z-index: -1;
    transition: ease all 0.35s;
  }
  .feature-box-1:hover {
    top: -5px;
  }
  .feature-box-1:hover h5 {
    color: #000000;
  }
  .feature-box-1:hover p {
    color: rgba(0, 0, 0, 0.8);
  }
  .feature-box-1:hover:after {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    left: 0;
    right: auto;
  }
  .feature-box-2 {
    padding: 32px;
    box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
    margin: 15px 0;
    position: relative;
    z-index: 1;
    border-radius: 10px;
    overflow: hidden;
    transition: ease all 0.35s;
    top: 0;
    height: 375px;
  }
  .feature-box-2 * {
    transition: ease all 0.35s;
  }
  .feature-box-2 .icon {
    width: 90px;
    height: 90px;
    line-height: 70px;
    /* background: #fc5356; */
    color: #ffffff;
    text-align: center;
    border-radius: 50%;
    margin-bottom: 22px;
    font-size: 27px;
  }
  .feature-box-2 .icon i {
    line-height: 70px;
  }
  .feature-box-2 h5 {
    color: #20247b;
    font-weight: 600;
  }
  .feature-box-2 p {
    margin: 0;
  }
  .feature-box-2:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: auto;
    right: 0;
    border-radius: 10px;
    width: 0;
    background: #fff2cc;
    z-index: -1;
    transition: ease all 0.35s;
  }
  .feature-box-2:hover {
    top: -5px;
  }
  .feature-box-2:hover h5 {
    color: #000000;
  }
  .feature-box-2:hover p {
    color: rgba(0, 0, 0, 0.8);
  }
  .feature-box-2:hover:after {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    left: 0;
    right: auto;
  }
  .feature-box-3 {
    padding: 32px;
    box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
    margin: 15px 0;
    position: relative;
    z-index: 1;
    border-radius: 10px;
    overflow: hidden;
    transition: ease all 0.35s;
    top: 0;
    height: 375px;
  }
  .feature-box-3 * {
    transition: ease all 0.35s;
  }
  .feature-box-3 .icon {
    width: 90px;
    height: 90px;
    line-height: 70px;
    /* background: #fc5356; */
    color: #ffffff;
    text-align: center;
    border-radius: 50%;
    margin-bottom: 22px;
    font-size: 27px;
  }
  .feature-box-3 .icon i {
    line-height: 70px;
  }
  .feature-box-3 h5 {
    color: #20247b;
    font-weight: 600;
  }
  .feature-box-3 p {
    margin: 0;
  }
  .feature-box-3:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: auto;
    right: 0;
    border-radius: 10px;
    width: 0;
    background: #c9daf8;
    z-index: -1;
    transition: ease all 0.35s;
  }
  .feature-box-3:hover {
    top: -5px;
  }
  .feature-box-3:hover h5 {
    color: #000000;
  }
  .feature-box-3:hover p {
    color: rgba(0, 0, 0, 0.8);
  }
  .feature-box-3:hover:after {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    left: 0;
    right: auto;
  }
  .section {
      padding: 100px 0;
      position: relative;
  }
  .section-title {
      padding-bottom: 45px;
  }
  .section-title{
    padding-bottom: 45px;
    /* align-items: center; */
  }
  .section-title h2 {
      font-weight: 700;
      color: #20247b;
      font-size: 45px;
      margin: 0 0 15px;
      border-left: 5px solid #fc5356;
      padding-left: 15px;
  }

/* Service Pages */
/*Data Analysis*/
.product-box-1 {
  padding: 0;
  height: 500px;
  border: 1px solid #e7eaec;
}
.product-box-1:hover,
.product-box-1.active {
  border: 1px solid transparent;
  box-shadow: 0 3px 7px 0 #a8a8a8;
}
.product-imitation {
  text-align: center;
  padding: 90px 0;
  background-color: #f8f8f9;
  color: #bebec3;
  font-weight: 600;
}
.cart-product-imitation {
  text-align: center;
  padding-top: 30px;
  height: 80px;
  width: 80px;
  background-color: #1e1ee0;
}
.product-imitation.xl {
  padding: 120px 0;
}
.product-desc {
  padding: 20px;
  position: relative;
}
.product-desc .text-muted{
  font-size: 13px;
}
.ecommerce .tag-list {
  padding: 0;
}
.ecommerce .fa-star {
  color: #d1dade;
}
.ecommerce .fa-star.active {
  color: #f8ac59;
}
.ecommerce .note-editor {
  border: 1px solid #e7eaec;
}
table.shoping-cart-table {
  margin-bottom: 0;
}
table.shoping-cart-table tr td {
  border: none;
  text-align: right;
}
table.shoping-cart-table tr td.desc,
table.shoping-cart-table tr td:first-child {
  text-align: left;
}
table.shoping-cart-table tr td:last-child {
  width: 80px;
}
.product-name {
  font-size: 18px;
  font-weight: 600;
  color: #3c3d3d;
  display: block;
  margin: 2px 0 5px 0;
}
.product-name:hover,
.product-name:focus {
  color: #1ab394;
}
.product-price {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background-color: #1ab394;
  padding: 6px 12px;
  position: absolute;
  top: -32px;
  right: 0;
}
.product-detail .ibox-content {
  padding: 30px 30px 50px 30px;
}
.image-imitation {
  background-color: #f8f8f9;
  text-align: center;
  padding: 200px 0;
}
.product-main-price small {
  font-size: 10px;
}
.product-images {
  margin: 0 20px;
}

.ibox-1 {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}
.ibox-1.collapsed .ibox-content-1 {
  display: none;
}
.ibox-1.collapsed .fa.fa-chevron-up:before {
  content: "\f078";
}
.ibox-1.collapsed .fa.fa-chevron-down:before {
  content: "\f077";
}
.ibox-1:after,
.ibox-1:before {
  display: table;
}
.ibox-title-1 {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #ffffff;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 3px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 14px 15px 7px;
  min-height: 48px;
}
.ibox-content-1 {
  background-color: #fff2cc;
}
.ibox-footer-1 {
  color: inherit;
  border-top: 1px solid #e7eaec;
  font-size: 90%;
  background: #ffffff;
  padding: 10px 15px;
}





/* Service Pages */
/*Data Generation*/
.product-box-2 {
  padding: 0;
  height: 458px;
  border: 1px solid #e7eaec;
}
.product-box-2:hover,
.product-box-2.active {
  border: 1px solid transparent;
  box-shadow: 0 3px 7px 0 #a8a8a8;
}
.product-imitation {
  text-align: center;
  padding: 90px 0;
  background-color: #f8f8f9;
  color: #bebec3;
  font-weight: 600;
}
.cart-product-imitation {
  text-align: center;
  padding-top: 30px;
  height: 80px;
  width: 80px;
  background-color: #1e1ee0;
}
.product-imitation.xl {
  padding: 120px 0;
}
.product-desc {
  padding: 20px;
  position: relative;
}
.product-desc .text-muted{
  font-size: 13px;
}
.ecommerce .tag-list {
  padding: 0;
}
.ecommerce .fa-star {
  color: #d1dade;
}
.ecommerce .fa-star.active {
  color: #f8ac59;
}
.ecommerce .note-editor {
  border: 1px solid #e7eaec;
}
table.shoping-cart-table {
  margin-bottom: 0;
}
table.shoping-cart-table tr td {
  border: none;
  text-align: right;
}
table.shoping-cart-table tr td.desc,
table.shoping-cart-table tr td:first-child {
  text-align: left;
}
table.shoping-cart-table tr td:last-child {
  width: 80px;
}
.product-name {
  font-size: 18px;
  font-weight: 600;
  color: #3c3d3d;
  display: block;
  margin: 2px 0 5px 0;
}
.product-name:hover,
.product-name:focus {
  color: #1ab394;
}
.product-price {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background-color: #1ab394;
  padding: 6px 12px;
  position: absolute;
  top: -32px;
  right: 0;
}
.product-detail .ibox-content {
  padding: 30px 30px 50px 30px;
}
.image-imitation {
  background-color: #f8f8f9;
  text-align: center;
  padding: 200px 0;
}
.product-main-price small {
  font-size: 10px;
}
.product-images {
  margin: 0 20px;
}

.ibox-2 {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}
.ibox-2.collapsed .ibox-content-1 {
  display: none;
}
.ibox-2.collapsed .fa.fa-chevron-up:before {
  content: "\f078";
}
.ibox-2.collapsed .fa.fa-chevron-down:before {
  content: "\f077";
}
.ibox-2:after,
.ibox-2:before {
  display: table;
}
.ibox-title-2 {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #ffffff;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 3px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 14px 15px 7px;
  min-height: 48px;
}
.ibox-content-2 {
  background-color: #d9ead3;
}
.ibox-footer-2 {
  color: inherit;
  border-top: 1px solid #e7eaec;
  font-size: 90%;
  background: #ffffff;
  padding: 10px 15px;
}







/* Service Pages */
/* MicroArrays */
.product-box-3 {
  padding: 0;
  height: 420px;
  border: 1px solid #e7eaec;
}
.product-box-3:hover,
.product-box-3.active {
  border: 1px solid transparent;
  box-shadow: 0 3px 7px 0 #a8a8a8;
}
.product-imitation {
  text-align: center;
  padding: 90px 0;
  background-color: #f8f8f9;
  color: #bebec3;
  font-weight: 600;
}
.cart-product-imitation {
  text-align: center;
  padding-top: 30px;
  height: 80px;
  width: 80px;
  background-color: #1e1ee0;
}
.product-imitation.xl {
  padding: 120px 0;
}
.product-desc {
  padding: 20px;
  position: relative;
}
.product-desc .text-muted{
  font-size: 13px;
}
.ecommerce .tag-list {
  padding: 0;
}
.ecommerce .fa-star {
  color: #d1dade;
}
.ecommerce .fa-star.active {
  color: #f8ac59;
}
.ecommerce .note-editor {
  border: 1px solid #e7eaec;
}
table.shoping-cart-table {
  margin-bottom: 0;
}
table.shoping-cart-table tr td {
  border: none;
  text-align: right;
}
table.shoping-cart-table tr td.desc,
table.shoping-cart-table tr td:first-child {
  text-align: left;
}
table.shoping-cart-table tr td:last-child {
  width: 80px;
}
.product-name {
  font-size: 18px;
  font-weight: 600;
  color: #3c3d3d;
  display: block;
  margin: 2px 0 5px 0;
}
.product-name:hover,
.product-name:focus {
  color: #1ab394;
}
.product-price {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background-color: #1ab394;
  padding: 6px 12px;
  position: absolute;
  top: -32px;
  right: 0;
}
.product-detail .ibox-content {
  padding: 30px 30px 50px 30px;
}
.image-imitation {
  background-color: #f8f8f9;
  text-align: center;
  padding: 200px 0;
}
.product-main-price small {
  font-size: 10px;
}
.product-images {
  margin: 0 20px;
}

.ibox-3 {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}
.ibox-3.collapsed .ibox-content-1 {
  display: none;
}
.ibox-3.collapsed .fa.fa-chevron-up:before {
  content: "\f078";
}
.ibox-3.collapsed .fa.fa-chevron-down:before {
  content: "\f077";
}
.ibox-3:after,
.ibox-3:before {
  display: table;
}
.ibox-title-3 {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #ffffff;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 3px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 14px 15px 7px;
  min-height: 48px;
}
.ibox-content-3 {
  background-color: #c9daf8;
}
.ibox-footer-3 {
  color: inherit;
  border-top: 1px solid #e7eaec;
  font-size: 90%;
  background: #ffffff;
  padding: 10px 15px;
}
/* Oligo Synthesis Form*/
/* .row{
  margin-top: 50px;
  margin-bottom: 50px;
} */

.masthead-text{
    height: 300px;
  }
  
  .form-control{
    height: 45px;
  }
  .form-control-2{
    height: 200px;
    background-color: #fff2cc;
  }
  .form-control-3{
    height: 300px;
    background-color: #fff2cc;
  }
  
  select:hover {
    color: #444645;
    background: #ddd;
  }
  
  .login-or {
      position: relative;
      font-size: 18px;
      color: #aaa;
      margin-top: 20px;
      margin-bottom: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .span-or {
      display: block;
      position: absolute;
      left: 50%;
      top: -2px;
      margin-left: -25px;
      background-color: #fff;
      width: 50px;
      text-align: center;
    }
    .hr-or {
      background-color: #cdcdcd;
      height: 1px;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  
  #login-dp{
      min-width: 250px;
      padding: 14px 14px 0;
      overflow:hidden;
      background-color:rgba(255,255,255,.8);
  }
  #login-dp .help-block{
      font-size:12px    
  }
  
  #login-dp .social-buttons{
      margin:12px 0    
  }
  #login-dp .social-buttons a{
      width: 49%;
  }
  #login-dp .form-group {
      margin-bottom: 10px;
  }
  .btn-fb{
      color: #fff;
      background-color:#3b5998;
  }
  .btn-fb:hover{
      color: #fff;
      background-color:#496ebc 
  }
  .btn-tw{
      color: #fff;
      background-color:#55acee;
  }
  .btn-tw:hover{
      color: #fff;
      background-color:#59b5fa;
  }
  @media(max-width:768px){
      #login-dp{
          background-color: inherit;
          color: #fff;
      }
      #login-dp .bottom{
          background-color: inherit;
          border-top:0 none;
      }
  }
  
  .progress {
    height: 5px;
  }
  
  .block-help {
          font-weight: 300;
      }
  
  .hide {
    display: none;
  }
body{
    margin-top:20px;
    background-color:#eee;
}
.fade-in {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    -webkit-animation: fadeIn 1s ease-in-out forwards;
            animation: fadeIn 1s ease-in-out forwards;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
.home-5-bg-1 {
    background: #1E90FF;
    position: relative;
    background-size: cover;
    height: 60vh;
    background-position: center center;
}
.team_member-1{
    height: 100vh;
}

.section {
    position: relative;
}

.home-5-content {
  z-index: 1;
  position: relative;
}

.bg-overlay {
    background-color: #000000a9;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.home-center {
    display: table;
    width: 100%;
    height: 100%;
}

.home-desc-center {
    display: table-cell;
    vertical-align: middle;
}

.home-5-content {
    z-index: 1;
    position: relative;
}

.text-white-70 {
    color: rgba(255, 255, 255, 0.8);
}

.f-15 {
    font-size: 15px;
}

.home-5-bg #particles-js {
    z-index: 1!important;
position: absolute;
    width: 100%;
    height: 100%;
}

body{margin-top:20px;
background-color:#f2f6fc;
color:#69707a;
}
.img-account-profile {
    height: 10rem;
}
.rounded-circle {
    border-radius: 50% !important;
}
.card {
    box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%);
}
.card .card-header {
    font-weight: 500;
}
.card-header:first-child {
    border-radius: 0.35rem 0.35rem 0 0;
}
.card-header {
    padding: 1rem 1.35rem;
    margin-bottom: 0;
    background-color: rgba(33, 40, 50, 0.03);
    border-bottom: 1px solid rgba(33, 40, 50, 0.125);
}
.form-control, .dataTable-input {
    display: block;
    width: 100%;
    padding: 0.875rem 1.125rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    color: #69707a;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c5ccd6;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.35rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.nav-borders .nav-link.active {
    color: #0061f2;
    border-bottom-color: #0061f2;
}
.nav-borders .nav-link {
    color: #69707a;
    border-bottom-width: 0.125rem;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0;
    padding-right: 0;
    margin-left: 1rem;
    margin-right: 1rem;
}
