/* .IN-PROGRESS{

} */

.NEGATIVE{
    background-color: #008800;
    color: white;
    font-weight: bolder;
    padding-left: 16px;
}

.POSITIVE{
    background-color: #C21F39;
    color: white;
    font-weight: bolder;
    padding-left: 16px;

}


.NO_ISSUES{
    background-color: #008800;
    color: white;
    font-weight: bolder;
    padding-left: 16px;
}
.HOME_QUARANTINE{
    background-color: #ee8816;
    color: black;
    font-weight: bolder;
    padding-left: 16px;
}

.ADMIT{
    background-color: #C21F39;
    color: white;
    font-weight: bolder;
    padding-left: 16px;

}

/* endlessloop image conatiner */
.image-row {
    overflow: hidden;
  }
  
  .image-container {
    transition: transform 0.9s ease-in-out;
    display: flex;
    overflow: hidden;
    position: relative;
  }
  
  .active {
    
    border: 2px solid #007bff;
  }

  .image {
    flex: 1;
    object-fit: cover;
    width:100px;
    height: 50px;
    margin-left: 50px;
    transition: transform 1s ease;
    background-blend-mode: lighten;
  }



  
  .image:not(:last-child) {
    margin-right: 10px; 
  }
  
  @media (max-width: 767px) {
    
    .image-container {
      margin-bottom: 10px;
    }
  }
  
/* bg image about us page */



/* login page design */


.marketplace {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.marketplace .serviceTitle {
  font-family: cursive;
  font-size: 60px;
}

.serviceList {
  width: 70vw;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr ;
  align-items: center;

}

/* MENU ITEM STYLING */

.serviceItem {
  border-radius: 15px;
  width: 285px;
  height: 500px;
  margin: 20px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  
  
}
.serviceItem:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.serviceItem div {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
}




@media only screen and (max-width: 1300px) {
  .serviceList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .serviceList {
    grid-template-columns: 1fr;
  }
}


.Image{
  border-radius: 50%;
}



 