
/* Hover - Publication */
.publication-item {
    transition: background-color 0.1s ease, font-weight 0.1s ease; 
  }
  
  .publication-item:hover {
    background-color: #eafff6; 
    font-weight: bold; 
  }
  .fade-in {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 1s ease-in-out forwards;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }


