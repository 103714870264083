/* Footer */
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
body {
    overflow-x: hidden;
}
.section-1 {
    padding: 20px 0;
}

.section-1 .section-1-title {
    text-align: center;
    color: #007b5e;
    margin-bottom: 50px;
    text-transform: uppercase;
}
#footer {
    /* background: #007b5e !important; */
    /* background: url("https://images.unsplash.com/photo-1483794344563-d27a8d18014e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D") center center no-repeat; */
    background-size: cover;
}
.h5{
	padding-left: 10px;
    /* border-left: 3px solid #000000; */
    padding-bottom: 2px;
    margin-bottom: 20px;
    color:#000000;
}
.h5-1{
    padding-left: 10px;
    border-left: 3px solid #000000;
    /* border-right: 3px solid #000000; */
    padding-bottom: 2px;
    margin-bottom: 20px;
    color:#000000;
}
.h5-2{
    padding-right: 10px;
    border-right: 3px solid #000000;
    padding-bottom: 6px;
    margin-bottom: 20px;
    color:#000000;
}
#footer a {
    color: #000000;
    text-decoration: none !important;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}
#footer ul.social li{
	padding: 1px 0;
}
#footer ul.social li a i {
    margin-right: 5px;
	font-size:25px;
	-webkit-transition: .5s all ease;
	-moz-transition: .5s all ease;
	transition: .5s all ease;
}
#footer ul.social li:hover a i {
	font-size:30px;
	margin-top:-10px;
}
#footer ul.social li a,
#footer ul.quick-links li a{
	color:#000000;
}
#footer ul.social li a:hover{
	color:#000000;
}
#footer ul.quick-links li{
	padding: 3px 0;
	-webkit-transition: .5s all ease;
	-moz-transition: .5s all ease;
	transition: .5s all ease;
}
#footer ul.quick-links li:hover{
	padding: 3px 0;
	margin-left:5px;
	font-weight:700;
}
#footer ul.quick-links li a i{
	margin-right: 5px;
}
#footer ul.quick-links li:hover a i {
    font-weight: 700;
}
#footer h5 {
    margin-bottom: 15px; /* Adjust the margin to reduce space between items */
  }

@media (max-width:767px){
	#footer h5 {
    padding-left: 0;
    border-left: transparent;
    padding-bottom: 0px;
    margin-bottom: 10px;
}
}


a:hover {
    color: #007bff; /* Change to your preferred hover color */
    text-decoration: underline;
  }