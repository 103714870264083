.searchInputs {
    
    display: flex;
  }
  
  .search input {
    background-color: white;
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 15px;
    padding: 15px;
    height: 30px;
    width: 300px;
  }
  
  .searchIcon {
    height: 30px;
    width: 20px;
    background-color: white;
    display: grid;
    place-items: center;
    cursor: pointer;
  }
  
  input:focus {
    outline: none;
  }
  .searchIcon svg {
    font-size: 25px;
  }
  
  .dataResult {
    margin-top: 5px;
    width: 300px;
    height: 200px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
    top: 100%;
    position: absolute;
  }
  
  .dataResult::-webkit-scrollbar {
    display: none;
  }
  
  .dataResult .dataItem {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: black;
  }
  
  .dataItem p {
    margin-left: 10px;
  }
  a {
    text-decoration: none;
  }
  
  a:hover {
    background-color: rgb(103, 222, 115);
  }
  
  #clearBtn {
    cursor: pointer;
  }